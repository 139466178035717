<template>
  <div v-if="store?.cart_visible">
    <div v-if="mounted" class="min-h-screen mevcut-container">
      <ClientOnly>
        <CartItemsCart :store-id-filter="store.id" />
      </ClientOnly>
    </div>
  </div>

  <div v-else class="flex flex-col items-center justify-center mt-20">
    <IconsEmptyCart class="w-20 h-20 sm:w-28 h-28" />

    <p class="mt-10 mb-5">{{ t('not_support_cart') }}</p>
  </div>
</template>

<script setup lang="ts">
const mounted = ref<boolean>(false)
const { store } = useDomainState()
if (store.value?.cart_visible) {
  await useCartStore()
    .serverLoad()
    .finally(() => {
      mounted.value = true
    })
}

const { url } = useDomainHost()
const { t } = useI18n()

useServerSeoMeta(
  useOgMeta(url + '/cart', t('cart'), store.value?.about, store.value?.thumb)
)

useHead({
  title: t('cart'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/cart')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, store.value?.name, url),
      useSeoBreadcrumbItem(2, t('cart'), url + '/cart')
    ])
  ]
})
</script>
